<template>
    <div>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateSix"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateSix"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterSix"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect3"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect3"
                                v-model="sortBy3"
                                :options="sortOptions3"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc3"
                                size="sm"
                                :disabled="!sortBy3"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput3"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput3"
                                v-model="filter3"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter3"
                                    @click="filter3 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <json-excel :data="sixTableExport()" name="cantidad_de_formularios_completados_por_rubro.xls"></json-excel>
                    <b-table
                        ref="table3"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage3"
                        :current-page="currentPage3"
                        :items="items3"
                        :fields="fields3"
                        :sort-by.sync="sortBy3"
                        :sort-desc.sync="sortDesc3"
                        :sort-direction="sortDirection3"
                        :filter="filter3"
                        :filter-included-fields="filterOn3"
                        @filtered="onFiltered3"
                        empty-text="No se encontraron registros coincidentes"
                        show-empty
                    >

                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap" v-if="data.item.business_area_name == 'TOTAL'"> </span>
                            <span class="text-nowrap" v-else>
                                {{ data.index+1 }}
                            </span>
                        </template>

                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                       
                        </template>

                        <template #cell(total)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.total }}</b>
                            <span v-else>{{ data.item.total }}</span>                       
                        </template>

                        <template #cell(alto_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.alto_cantidad }}</b>
                            <span v-else>{{ data.item.alto_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(medio_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.medio_cantidad }}</b>
                            <span v-else>{{ data.item.medio_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(bajo_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.bajo_cantidad }}</b>
                            <span v-else>{{ data.item.bajo_cantidad }}</span>                       
                        </template>
    
                    </b-table>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from '@axios';

    export default {
        components: {
            flatPickr,
            VueApexCharts,
            JsonExcel
        },
        data() {
            return {                
                perPage3: 50,
                pageOptions3: [10, 15, 20, 25, 30],
                totalRows3: 1,
                currentPage3: 1,

                sortBy3: '',
                sortDesc3: false,
                sortDirection3: 'asc',
                filter3: null,   
                filterOn3: [],
                fields3: [],
                items3: [],

                // ======================
               
                starDateSix: "",
                endDateSix: "",
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },
                       
                fieldsThree: [
                    {key: 'index', label: "#"},
                    {key: "business_area_name", label: "Rubro"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsThree: [],
                fieldsQuarter: [
                    {key: 'index', label: "#"},
                    {key: "form_state_name", label: "ESTADO DEL FORMULARIO"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
            }
        },
        computed: {
            sortOptions3() {
                // Create an options list from our fields
                return this.fields3
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },
        },
        beforeMount() {
            this.getDataTableSexto();
        },
        methods: {
            async getDataTableSexto (){

                let response = await axios.get(`/reporteria/table-3?filterStartDate=${this.starDateSix}&filterEndDate=${this.endDateSix}`);
                
                this.fields3 = response.data.fields;
                this.items3 = response.data.data;

                this.totalRows3 = response.data.data.length;

                this.currentPage3 = 1;
                this.sortBy3 = '';
                this.sortDesc3 = false;
                this.sortDirection3 = 'asc';
                this.filter3 = null;
            },
            async filterSix (){
                await this.getDataTableSexto();
                this.$refs['table3'].refresh();
            },
            onFiltered3(filteredItems) {
                this.totalRows3 = filteredItems.length;
                this.currentPage3 = 1;
            },
            sixTableExport (){

                let array = [];

                for (let a = 0; a < this.items3.length; a++)
                {
                    let objBody = {
                        'ITEM': (a+1 == this.items3.length ? '' : a+1),
                        'CANTIDAD FORMULARIOS COMPLETADOS POR RUBRO': this.items3[a]["business_area_name"],
                        'TOTAL': this.items3[a]["total"],
                        'ALTO': this.items3[a]["alto_cantidad"],
                        'ALTO (%)': this.items3[a]["alto_porcentaje"],
                        'MEDIO': this.items3[a]["medio_cantidad"],
                        'MEDIO (%)': this.items3[a]["medio_porcentaje"],
                        'BAJO': this.items3[a]["bajo_cantidad"],
                        'BAJO (%)': this.items3[a]["bajo_porcentaje"]
                    };

                    array.push(objBody);
                }

                return array;
            },
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
